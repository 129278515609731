import { storeToRefs } from 'pinia';
import { Comment } from 'vue';

export default defineNuxtComponent({
    name: 'ProductTypeComponent',
    props: {
        hideOn: {
            type: [Array, String],
        },
        noFallback: Boolean,
    },
    setup(props, { slots }) {
        const { $pinia } = useNuxtApp();

        const { currentProductType } = storeToRefs(useProductStore($pinia));

        // convert hideOn to array if it's not
        const hiddenOn = computed(() =>
            props.hideOn
                ? Array.isArray(props.hideOn)
                    ? props.hideOn
                    : [props.hideOn]
                : []
        );

        if (hiddenOn.value.includes(currentProductType.value)) {
            return () => h(Comment);
        }

        const hasSlots = !!(
            slots[currentProductType.value] ??
            slots.fallback ??
            slots.default
        );

        // check if slot for current product exists or we have a fallback
        if (!hasSlots && props.noFallback) {
            return () => h(Comment);
        } else if (!hasSlots) {
            console.error(
                `No template found for product ${currentProductType.value}, fallback or default.`
            );

            return () =>
                h(
                    'div',
                    `No template found for product ${currentProductType.value}, fallback or default.`
                );
        }

        // return slot corresponding to current product
        return () =>
            h(
                slots[currentProductType.value] ??
                    slots.fallback ??
                    slots.default
            );
    },
});
